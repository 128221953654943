// Component For Navigation Bar

import '../css/componentscss/NavigationBar.css';
import Image from './Image';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clientUrls } from '../constants/UrlConstants';
import { useEffect, useRef, useState } from 'react'; //useState
import { getNavigationBarData } from '../apiservices/ApiService';
import {
  getLanguage,
  languageCodes,
  languagesObj,
  navigateTo,
  stringToUpperCase,
  userAgent,
} from '../constants/GeneralConstants';
import { setFooterData, setLanguage } from '../store/reducers/LanguageReducer';

const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const languageMenuRef = useRef(null);
  const languageMenuRefForMobile = useRef(null);
  const [navBarData, setNavBarData] = useState([]);
  const [displayLanguageBox, setDisplayLanguageBox] = useState(false);
  const [displayLanguageBoxForMobile, setDisplayLanguageBoxForMobile] =
    useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedLanguageValue, setSelectedLanguageValue] = useState('en');
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);

  useEffect(() => {
    // getNavBarData(getLanguage());
    setSelectedLanguageValue(getLanguage() === 'en' ? 'en' : getLanguage());
  }, []);

  useEffect(() => {
    const handleLanguageChange = () => {
      setAppLanguage();
    };

    setAppLanguage();

    window.addEventListener('languagechange', handleLanguageChange);
    return () => {
      window.addEventListener('languagechange', handleLanguageChange);
    };
  }, []);

  const setAppLanguage = () => {
    let url = window.location.href.split('/');
    let lang = url[3] ? url[3] : '';
    let index = languagesObj.findIndex((obj) => obj.languageCode === lang);
    const browserLanguage = getBrowserLanguage();
    if (index !== -1 && lang.length > 1) {
      changeLanguage(languagesObj[index]);
    } else if (browserLanguage !== undefined) {
      changeLanguage(browserLanguage);
    } else if (index === -1 && lang === '') {
      changeLanguage(languagesObj[0]);
    } else {
      changeLanguage(languagesObj[0]);
    }
  };

  const getBrowserLanguage = () => {
    let browserLanguage = navigator.language;
    const foundLanguage = languagesObj.find(
      (lan) => lan.browserLanguageCode === browserLanguage,
    );
    return foundLanguage;
  };

  const getNavBarData = async (language) => {
    try {
      let data = await getNavigationBarData(language);
      // console.log(data);
      setNavBarData(data.header);
      // data?.header?.languages.filter((index.language === selectedLanguageValue))
      const lan = data?.header?.languages;
      const urlLang = lan?.find(
        (language) => language.languageCode === getLanguage(),
      )?.languageValue;
      setSelectedLanguage(urlLang);

      dispatch(setFooterData(data.header.footerData));
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickOutside = (e) => {
    if (
      languageMenuRef.current &&
      !languageMenuRef.current.contains(e.target)
    ) {
      setDisplayLanguageBox(false);
    }
  };

  const handleTouchOutside = (e) => {
    if (
      languageMenuRefForMobile.current &&
      !languageMenuRefForMobile.current.contains(e.target)
    ) {
      setDisplayLanguageBoxForMobile(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('click', handleTouchOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('click', handleTouchOutside, true);
    };
  }, []);

  const handleScroll = () => {
    // console.log('Desktop : ', displayLanguageBox)
    // if(displayLanguageBox) {
    setDisplayLanguageBox(false);
    // }

    // console.log('Mobile : ', displayLanguageBoxForMobile)
    // if(displayLanguageBoxForMobile) {
    setDisplayLanguageBoxForMobile(false);
    // }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const changeLanguage = (selectedObj) => {
    setDisplayLanguageBox(false);
    setDisplayLanguageBoxForMobile(false);
    let selectedLanguageCode = selectedObj.languageCode;
    setSelectedLanguage(selectedObj.languageValue);
    setSelectedLanguageValue(selectedLanguageCode);
    let existingUrl = location.pathname;
    languageCodes.some((code) => {
      if (existingUrl.includes(`/${code}`)) {
        existingUrl = existingUrl.replace(`/${code}`, '');
        return true;
      }
      return false;
    });

    if (existingUrl === '/') {
      existingUrl = '';
    }

    selectedLanguageCode === 'en'
      ? navigate(`../${existingUrl}`, { replace: true })
      : navigate(`../${selectedLanguageCode}${existingUrl}`, { replace: true });
    setDisplayMobileMenu(false);
    getNavBarData(selectedLanguageCode);
    dispatch(setLanguage(selectedLanguageCode));
  };

  const navigateToPage = (url) => {
    navigate(getLanguage() === 'en' ? url : `${getLanguage()}${url}`);
    setDisplayMobileMenu(false);
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      document.body.classList.remove('fixed');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  };

  const handleMobileMenuSlideWindowShow = () => {
    setDisplayMobileMenu(!displayMobileMenu);
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      document.body.classList.add('fixed');
    } else {
      document.body.classList.add('overflow-hidden');
    }
  };

  const handleMobileMenuSlideWindowHide = () => {
    setDisplayMobileMenu(!displayMobileMenu);
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      document.body.classList.remove('fixed');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  };

  return (
    <>
      <header
        id="desktop-header"
        className="bg-black py-4 lg:fixed w-full z-[999] top-0 hidden lg:block"
      >
        <nav
          className="flex justify-between
					items-center w-[95%] mx-auto"
        >
          <div
            className="font-bold text-white"
            onClick={(e) => {
              navigate(navigateTo(clientUrls.home));
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
            }}
          >
            <Image
              // src={navBarData?.artLogo}
              src="/art/images/Side Art-logo.svg"
              // src='/images/1518studios-Logo6.png'
              className="cursor-pointer w-auto h-[65px] 3xl:h-[70px]"
            />
            {/* <Image src="/art/images/1518-Logo.png" className="cursor-pointer" /> */}
          </div>
          <div className="nav-links md:static text-white absolute md:min-h-fit min-h-[60vh] left-0 top-[-100%] md:w-auto w-full flex items-center px-5 bg-black">
            <ul className="flex md:flex-row flex-col md:items-center md:gap-[5vw] gap-8">
              <li>
                <NavLink
                  className="hover:text-red-500"
                  to={navigateTo(clientUrls.services)}
                >
                  {navBarData?.servicesText
                    ? stringToUpperCase(navBarData.servicesText)
                    : ''}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="hover:text-red-500"
                  to={navigateTo(clientUrls.projects)}
                >
                  {navBarData?.projectsText
                    ? stringToUpperCase(navBarData.projectsText)
                    : ''}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="hover:text-red-500"
                  to={navigateTo(clientUrls.contact)}
                >
                  {navBarData?.contactText
                    ? stringToUpperCase(navBarData.contactText)
                    : ''}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="hover:text-red-500"
                  to={navigateTo(clientUrls.portfolio)}
                >
                  {navBarData?.portfolioText
                    ? stringToUpperCase(navBarData.portfolioText)
                    : ''}
                </NavLink>
              </li>
              <li ref={languageMenuRef}>
                {selectedLanguage?.length > 0 ? (
                  <div className="flex items-center space-x-2">
                    {/* <a /> */}
                    <span
                      className="text-lg hover:text-[#FF2E29] cursor-pointer"
                      onClick={(e) =>
                        setDisplayLanguageBox(!displayLanguageBox)
                      }
                    >
                      {selectedLanguage}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 cursor-pointer hover:text-[#FF2E29]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      onClick={(e) =>
                        setDisplayLanguageBox(!displayLanguageBox)
                      }
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                ) : (
                  ''
                )}
                {displayLanguageBox && (
                  <div className="lang_dropdown">
                    <>
                      {navBarData?.languages
                        ? navBarData.languages.map((language, index) => {
                            return (
                              <p
                                onClick={(e) => changeLanguage(language)}
                                className={
                                  language.languageCode ===
                                  selectedLanguageValue
                                    ? 'active'
                                    : ''
                                }
                                value={language.languageCode}
                                key={index}
                              >
                                {language.language}
                              </p>
                            );
                          })
                        : ''}
                    </>
                  </div>
                )}
              </li>
            </ul>
          </div>
          <div className="flex items-center gap-8 md:hidden">
            <div ref={languageMenuRefForMobile}>
              <div className="flex items-center space-x-2">
                {/* <a /> */}
                <span
                  className="text-lg text-red-500 hover:text-#FF2E29 cursor-pointer"
                  onClick={(e) =>
                    setDisplayLanguageBoxForMobile(!displayLanguageBoxForMobile)
                  }
                >
                  {selectedLanguage}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  onClick={(e) =>
                    setDisplayLanguageBoxForMobile(!displayLanguageBoxForMobile)
                  }
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              {displayLanguageBoxForMobile && (
                <div className="lang_dropdown">
                  <>
                    {navBarData?.languages
                      ? navBarData.languages.map((language, index) => {
                          return (
                            <p
                              onClick={(e) => {
                                changeLanguage(language);
                              }}
                              className={
                                language.languageCode === selectedLanguageValue
                                  ? 'active'
                                  : ''
                              }
                              value={language.languageCode}
                              key={index}
                            >
                              {language.language}
                            </p>
                          );
                        })
                      : ''}
                  </>
                </div>
              )}
            </div>
            {!displayMobileMenu && (
              <div onClick={(e) => handleMobileMenuSlideWindowShow()}>
                <Image
                  src="/art/images/menu-icon.png"
                  name="menu"
                  alt={'Ptw Art'}
                  className="text-3xl cursor-pointer md:hidden text-white"
                />
              </div>
            )}
            {displayMobileMenu && (
              <div
                onClick={(e) => handleMobileMenuSlideWindowHide()}
                className="cursor-pointer"
              >
                <svg
                  className="close_menu"
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.74857 9.06966L16.4685 16.7896L17.1756 16.0825L9.45568 8.36255L17.1189 0.699294L16.4118 -0.0078125L8.74857 7.65545L1.08751 -0.0056193L0.380403 0.701487L8.04147 8.36255L0.3237 16.0803L1.03081 16.7874L8.74857 9.06966Z"
                    fill="white"
                  ></path>
                </svg>
              </div>
            )}
          </div>
        </nav>
        {displayMobileMenu && (
          <div className="sm:hidden" id="mobile-menu">
            <div className="flex-1 flex-col h-screen flex justify-around">
              <div className="bg-dark text-white">
                <div className="submenu-top p-5">
                  <div
                    onClick={(e) => navigateToPage(clientUrls.services)}
                    className=" block cursor-pointer"
                  >
                    {navBarData?.servicesText
                      ? stringToUpperCase(navBarData.servicesText)
                      : ''}
                  </div>
                  <div
                    onClick={(e) => navigateToPage(clientUrls.projects)}
                    className=" block cursor-pointer"
                  >
                    {navBarData?.projectsText
                      ? stringToUpperCase(navBarData.projectsText)
                      : ''}
                  </div>
                  <div
                    onClick={(e) => navigateToPage(clientUrls.contact)}
                    className=" block cursor-pointer"
                  >
                    {navBarData?.contactText
                      ? stringToUpperCase(navBarData.contactText)
                      : ''}
                  </div>
                  <div
                    onClick={(e) => navigateToPage(clientUrls.portfolio)}
                    className=" block cursor-pointer"
                  >
                    {navBarData?.portfolioText
                      ? stringToUpperCase(navBarData.portfolioText)
                      : ''}
                  </div>
                </div>
              </div>
              <div className="flex bg-dark">
                <div className="submenu-bottom p-5">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={
                      navBarData?.footerData?.socialMediaLogosWithLinks
                        ?.artStationLink
                        ? navBarData?.footerData?.socialMediaLogosWithLinks
                            ?.artStationLink
                        : ''
                    }
                    className="pb-2 block"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 38 35"
                      fill="#fff"
                    >
                      <g clipPath="url(#clip0_41_260)">
                        <path
                          d="M19.2591 0.337891C20.2948 0.337891 21.3306 0.337891 22.3664 0.337891C24.0495 0.337891 25.3442 1.13074 26.1858 2.61735C28.9695 7.7709 31.7531 12.9575 34.5367 18.1441C35.4754 19.928 36.4141 21.6789 37.3851 23.4628C38.2591 25.0485 38.2267 26.6342 37.3851 28.1869C36.3817 30.0038 35.4107 31.8538 34.4073 33.6708C34.0836 34.2654 33.6628 34.6288 32.9507 34.6288C32.271 34.6288 31.8502 34.2654 31.5589 33.6708C25.9269 23.3637 20.2625 13.0566 14.6304 2.74949C14.1449 1.89056 14.3068 1.03164 14.9865 0.56914C15.2131 0.403962 15.5044 0.337891 15.8604 0.337891C16.9933 0.337891 18.1262 0.337891 19.2914 0.337891H19.2591ZM32.9183 30.0369C33.5333 28.9137 34.0512 27.8896 34.6339 26.8985C35.087 26.1387 35.0546 25.478 34.6339 24.7182C30.9439 17.9128 27.3187 11.1075 23.6611 4.26913C23.3374 3.64145 22.8519 3.34413 22.1398 3.34413C21.2335 3.34413 20.3596 3.34413 19.4533 3.34413C18.3528 3.34413 18.3528 3.34413 18.903 4.3352C22.9814 11.8012 27.0597 19.3003 31.1381 26.7664C31.7207 27.8235 32.3034 28.8806 32.9183 30.0038V30.0369Z"
                          fill="white"
                        />
                        <path
                          d="M12.1704 24.1569C15.7308 24.1569 19.2913 24.1569 22.8518 24.1569C23.5639 24.1569 24.1141 24.5533 24.3407 25.2471C24.5349 25.8747 24.3083 26.5355 23.7581 26.9319C23.4344 27.1631 23.1107 27.1962 22.7223 27.1962C19.3237 27.1962 15.925 27.1962 12.5264 27.1962C9.9046 27.1962 7.31516 27.1962 4.69335 27.1962C4.1431 27.1962 4.1431 27.1962 4.40204 27.6917C4.9523 28.6497 5.50255 29.6078 6.02044 30.5988C6.40885 31.3256 6.92674 31.5899 7.70357 31.5899C14.1124 31.5899 20.4889 31.5899 26.8978 31.5899C27.9336 31.5899 28.5809 32.2506 28.5162 33.2086C28.4838 33.9354 27.9012 34.5631 27.1891 34.6292C27.0273 34.6292 26.8654 34.6292 26.7036 34.6292C20.3595 34.6292 14.0153 34.6292 7.63884 34.6292C5.79386 34.6292 4.40204 33.8363 3.49574 32.1845C2.45996 30.3015 1.39182 28.4515 0.291307 26.6015C-0.38842 25.4783 0.291307 24.0908 1.7155 24.0908C5.21124 24.1569 8.70698 24.0908 12.2027 24.0908L12.1704 24.1569Z"
                          fill="white"
                        />
                        <path
                          d="M12.3969 22.2738C9.83982 22.2738 7.28275 22.2738 4.75805 22.2738C3.23676 22.2738 2.55703 21.1175 3.30149 19.7631C5.89093 15.1711 8.51274 10.5792 11.1345 6.02025C11.8466 4.79794 13.2061 4.79794 13.8858 6.02025C16.4753 10.6452 19.0323 15.2702 21.6218 19.8952C22.2691 21.0514 21.557 22.2738 20.2623 22.2738C17.6405 22.2738 15.0511 22.2738 12.4293 22.2738H12.3969ZM12.3645 19.1684C13.9506 19.1684 15.569 19.1684 17.155 19.1684C17.511 19.1684 17.6081 19.1023 17.4139 18.739C15.8279 15.8979 14.2419 13.0238 12.6235 10.1827C12.3969 9.78631 12.3645 9.78631 12.1379 10.1827C10.5519 13.0238 8.96589 15.8649 7.34749 18.7059C7.08855 19.1684 7.08855 19.2015 7.6388 19.2015C9.19246 19.2015 10.7785 19.2015 12.3322 19.2015L12.3645 19.1684Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </Link>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={
                      navBarData?.footerData?.socialMediaLogosWithLinks?.xLink
                        ? navBarData?.footerData?.socialMediaLogosWithLinks
                            ?.xLink
                        : ''
                    }
                    className="pb-2 block"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="22"
                      height="22"
                      viewBox="0 0 50 50"
                      fill="#fff"
                    >
                      <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
                    </svg>
                  </Link>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={
                      navBarData?.footerData?.socialMediaLogosWithLinks
                        ?.instagramLink
                        ? navBarData?.footerData?.socialMediaLogosWithLinks
                            ?.instagramLink
                        : ''
                    }
                    className="pb-2 block"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="22"
                      height="22"
                      viewBox="0 0 26 26"
                      fill="#fff"
                    >
                      <path d="M 7.546875 0 C 3.390625 0 0 3.390625 0 7.546875 L 0 18.453125 C 0 22.609375 3.390625 26 7.546875 26 L 18.453125 26 C 22.609375 26 26 22.609375 26 18.453125 L 26 7.546875 C 26 3.390625 22.609375 0 18.453125 0 Z M 7.546875 2 L 18.453125 2 C 21.527344 2 24 4.46875 24 7.546875 L 24 18.453125 C 24 21.527344 21.53125 24 18.453125 24 L 7.546875 24 C 4.472656 24 2 21.53125 2 18.453125 L 2 7.546875 C 2 4.472656 4.46875 2 7.546875 2 Z M 20.5 4 C 19.671875 4 19 4.671875 19 5.5 C 19 6.328125 19.671875 7 20.5 7 C 21.328125 7 22 6.328125 22 5.5 C 22 4.671875 21.328125 4 20.5 4 Z M 13 6 C 9.144531 6 6 9.144531 6 13 C 6 16.855469 9.144531 20 13 20 C 16.855469 20 20 16.855469 20 13 C 20 9.144531 16.855469 6 13 6 Z M 13 8 C 15.773438 8 18 10.226563 18 13 C 18 15.773438 15.773438 18 13 18 C 10.226563 18 8 15.773438 8 13 C 8 10.226563 10.226563 8 13 8 Z"></path>
                    </svg>
                  </Link>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={
                      navBarData?.footerData?.socialMediaLogosWithLinks
                        ?.linkedinLink
                        ? navBarData?.footerData?.socialMediaLogosWithLinks
                            ?.linkedinLink
                        : ''
                    }
                    className="pb-2 block"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="22"
                      height="22"
                      viewBox="0 0 26 26"
                      fill="#fff"
                    >
                      <path d="M 21.125 0 L 4.875 0 C 2.183594 0 0 2.183594 0 4.875 L 0 21.125 C 0 23.816406 2.183594 26 4.875 26 L 21.125 26 C 23.816406 26 26 23.816406 26 21.125 L 26 4.875 C 26 2.183594 23.816406 0 21.125 0 Z M 8.039063 22.070313 L 4 22.070313 L 3.976563 9.976563 L 8.015625 9.976563 Z M 5.917969 8.394531 L 5.894531 8.394531 C 4.574219 8.394531 3.722656 7.484375 3.722656 6.351563 C 3.722656 5.191406 4.601563 4.3125 5.945313 4.3125 C 7.289063 4.3125 8.113281 5.191406 8.140625 6.351563 C 8.140625 7.484375 7.285156 8.394531 5.917969 8.394531 Z M 22.042969 22.070313 L 17.96875 22.070313 L 17.96875 15.5 C 17.96875 13.910156 17.546875 12.828125 16.125 12.828125 C 15.039063 12.828125 14.453125 13.558594 14.171875 14.265625 C 14.066406 14.519531 14.039063 14.867188 14.039063 15.222656 L 14.039063 22.070313 L 9.945313 22.070313 L 9.921875 9.976563 L 14.015625 9.976563 L 14.039063 11.683594 C 14.5625 10.875 15.433594 9.730469 17.519531 9.730469 C 20.105469 9.730469 22.039063 11.417969 22.039063 15.046875 L 22.039063 22.070313 Z"></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>

      <header
        id="mobile-header"
        className="bg-black py-4 fixed w-full z-[999] top-0 block lg:hidden"
      >
        <nav
          className="flex justify-between
					items-center w-[95%] mx-auto h-[3.1rem]"
        >
          <div
            className="font-bold text-white"
            onClick={(e) => {
              navigate(
                getLanguage() === 'en' ? clientUrls.home : `/${getLanguage()}`,
              );
              setDisplayMobileMenu(false);
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
              if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                document.body.classList.remove('fixed');
              } else {
                document.body.classList.remove('overflow-hidden');
              }
            }}
          >
            {/* <Image src={navBarData.artLogo} className="cursor-pointer" /> */}
            <Image
              // src={navBarData?.artLogo}
              src="/art/images/Side Art-logo.svg"
              // src='/images/1518studios-Logo6.png'
              className="cursor-pointer w-auto h-[65px] 3xl:h-[70px]"
            />
            {/* <Image src="/art/images/1518-Logo.png" className="cursor-pointer" /> */}
          </div>
          {/* <div className="nav-links md:static text-white absolute md:min-h-fit min-h-[60vh] left-0 top-[-100%] md:w-auto w-full flex items-center px-5 bg-black">
          </div> */}
          <div className="flex items-center gap-8 lg:hidden">
            {!displayMobileMenu && (
              <div onClick={(e) => handleMobileMenuSlideWindowShow()}>
                <Image
                  src="/art/images/menu-icon.png"
                  name="menu"
                  alt={'Ptw Art'}
                  className="text-3xl cursor-pointer lg:hidden text-white"
                />
              </div>
            )}
            {displayMobileMenu && (
              <div
                onClick={(e) => handleMobileMenuSlideWindowHide()}
                className="cursor-pointer"
              >
                <svg
                  className="close_menu"
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.74857 9.06966L16.4685 16.7896L17.1756 16.0825L9.45568 8.36255L17.1189 0.699294L16.4118 -0.0078125L8.74857 7.65545L1.08751 -0.0056193L0.380403 0.701487L8.04147 8.36255L0.3237 16.0803L1.03081 16.7874L8.74857 9.06966Z"
                    fill="white"
                  ></path>
                </svg>
              </div>
            )}
          </div>
        </nav>
        {displayMobileMenu && (
          <div
            id="mobile-menu"
            className="lg:hidden bg-black flex justify-between flex-col fixed top-16 left-0 overflow-y-auto overflow-x-hidden  mobile-menu-h w-full"
          >
            <div className="submenu-top px-5 mt-6 z-50 relative ">
              <div
                onClick={(e) => navigateToPage(clientUrls.services)}
                className=" block cursor-pointer"
              >
                {navBarData?.servicesText
                  ? stringToUpperCase(navBarData.servicesText)
                  : ''}
              </div>
              <div
                onClick={(e) => navigateToPage(clientUrls.projects)}
                className=" block cursor-pointer"
              >
                {navBarData?.projectsText
                  ? stringToUpperCase(navBarData.projectsText)
                  : ''}
              </div>
              <div
                onClick={(e) => navigateToPage(clientUrls.contact)}
                className=" block cursor-pointer"
              >
                {navBarData?.contactText
                  ? stringToUpperCase(navBarData.contactText)
                  : ''}
              </div>
              <div
                onClick={(e) => navigateToPage(clientUrls.portfolio)}
                className=" block cursor-pointer"
              >
                {navBarData?.portfolioText
                  ? stringToUpperCase(navBarData.portfolioText)
                  : ''}
              </div>
              <div ref={languageMenuRefForMobile} className="">
                <div className="flex items-center space-x-2">
                  {/* <a /> */}
                  <span
                    className="hover:text-#FF2E29 cursor-pointer text-[32px] font-extrabold "
                    onClick={(e) =>
                      setDisplayLanguageBoxForMobile(
                        !displayLanguageBoxForMobile,
                      )
                    }
                  >
                    {selectedLanguage}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8 cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    onClick={(e) =>
                      setDisplayLanguageBoxForMobile(
                        !displayLanguageBoxForMobile,
                      )
                    }
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
                {displayLanguageBoxForMobile && (
                  <div className="lang_dropdown p-2 bg-[#0c0c0c] border border-white mobile-lang mt-2 absolute">
                    <>
                      {navBarData?.languages
                        ? navBarData.languages.map((language, index) => {
                            return (
                              <p
                                onClick={(e) => {
                                  changeLanguage(language);
                                  if (
                                    /iPad|iPhone|iPod/.test(userAgent) &&
                                    !window.MSStream
                                  ) {
                                    document.body.classList.remove('fixed');
                                  } else {
                                    document.body.classList.remove(
                                      'overflow-hidden',
                                    );
                                  }
                                }}
                                className={
                                  language.languageCode ===
                                  selectedLanguageValue
                                    ? 'active'
                                    : ''
                                }
                                value={language.languageCode}
                                key={index}
                              >
                                {language.language}
                              </p>
                            );
                          })
                        : ''}
                    </>
                  </div>
                )}
              </div>
            </div>

            {/* <div className="mobile-shadow absolute right-0">
                <Image src="/art/images/mobile-menu-shadow.png" />
              </div> */}
            <div className="submenu-bottom px-5 pt-3 pb-6">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={
                  navBarData?.footerData?.socialMediaLogosWithLinks
                    ?.artStationLink
                    ? navBarData?.footerData?.socialMediaLogosWithLinks
                        ?.artStationLink
                    : ''
                }
                className="pb-2 block"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 38 35"
                  fill="#fff"
                >
                  <g clipPath="url(#clip0_41_260)">
                    <path
                      d="M19.2591 0.337891C20.2948 0.337891 21.3306 0.337891 22.3664 0.337891C24.0495 0.337891 25.3442 1.13074 26.1858 2.61735C28.9695 7.7709 31.7531 12.9575 34.5367 18.1441C35.4754 19.928 36.4141 21.6789 37.3851 23.4628C38.2591 25.0485 38.2267 26.6342 37.3851 28.1869C36.3817 30.0038 35.4107 31.8538 34.4073 33.6708C34.0836 34.2654 33.6628 34.6288 32.9507 34.6288C32.271 34.6288 31.8502 34.2654 31.5589 33.6708C25.9269 23.3637 20.2625 13.0566 14.6304 2.74949C14.1449 1.89056 14.3068 1.03164 14.9865 0.56914C15.2131 0.403962 15.5044 0.337891 15.8604 0.337891C16.9933 0.337891 18.1262 0.337891 19.2914 0.337891H19.2591ZM32.9183 30.0369C33.5333 28.9137 34.0512 27.8896 34.6339 26.8985C35.087 26.1387 35.0546 25.478 34.6339 24.7182C30.9439 17.9128 27.3187 11.1075 23.6611 4.26913C23.3374 3.64145 22.8519 3.34413 22.1398 3.34413C21.2335 3.34413 20.3596 3.34413 19.4533 3.34413C18.3528 3.34413 18.3528 3.34413 18.903 4.3352C22.9814 11.8012 27.0597 19.3003 31.1381 26.7664C31.7207 27.8235 32.3034 28.8806 32.9183 30.0038V30.0369Z"
                      fill="white"
                    />
                    <path
                      d="M12.1704 24.1569C15.7308 24.1569 19.2913 24.1569 22.8518 24.1569C23.5639 24.1569 24.1141 24.5533 24.3407 25.2471C24.5349 25.8747 24.3083 26.5355 23.7581 26.9319C23.4344 27.1631 23.1107 27.1962 22.7223 27.1962C19.3237 27.1962 15.925 27.1962 12.5264 27.1962C9.9046 27.1962 7.31516 27.1962 4.69335 27.1962C4.1431 27.1962 4.1431 27.1962 4.40204 27.6917C4.9523 28.6497 5.50255 29.6078 6.02044 30.5988C6.40885 31.3256 6.92674 31.5899 7.70357 31.5899C14.1124 31.5899 20.4889 31.5899 26.8978 31.5899C27.9336 31.5899 28.5809 32.2506 28.5162 33.2086C28.4838 33.9354 27.9012 34.5631 27.1891 34.6292C27.0273 34.6292 26.8654 34.6292 26.7036 34.6292C20.3595 34.6292 14.0153 34.6292 7.63884 34.6292C5.79386 34.6292 4.40204 33.8363 3.49574 32.1845C2.45996 30.3015 1.39182 28.4515 0.291307 26.6015C-0.38842 25.4783 0.291307 24.0908 1.7155 24.0908C5.21124 24.1569 8.70698 24.0908 12.2027 24.0908L12.1704 24.1569Z"
                      fill="white"
                    />
                    <path
                      d="M12.3969 22.2738C9.83982 22.2738 7.28275 22.2738 4.75805 22.2738C3.23676 22.2738 2.55703 21.1175 3.30149 19.7631C5.89093 15.1711 8.51274 10.5792 11.1345 6.02025C11.8466 4.79794 13.2061 4.79794 13.8858 6.02025C16.4753 10.6452 19.0323 15.2702 21.6218 19.8952C22.2691 21.0514 21.557 22.2738 20.2623 22.2738C17.6405 22.2738 15.0511 22.2738 12.4293 22.2738H12.3969ZM12.3645 19.1684C13.9506 19.1684 15.569 19.1684 17.155 19.1684C17.511 19.1684 17.6081 19.1023 17.4139 18.739C15.8279 15.8979 14.2419 13.0238 12.6235 10.1827C12.3969 9.78631 12.3645 9.78631 12.1379 10.1827C10.5519 13.0238 8.96589 15.8649 7.34749 18.7059C7.08855 19.1684 7.08855 19.2015 7.6388 19.2015C9.19246 19.2015 10.7785 19.2015 12.3322 19.2015L12.3645 19.1684Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </Link>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={
                  navBarData?.footerData?.socialMediaLogosWithLinks?.xLink
                    ? navBarData?.footerData?.socialMediaLogosWithLinks?.xLink
                    : ''
                }
                className="pb-2 block"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="22"
                  height="22"
                  viewBox="0 0 50 50"
                  fill="#fff"
                >
                  <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
                </svg>
              </Link>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={
                  navBarData?.footerData?.socialMediaLogosWithLinks
                    ?.instagramLink
                    ? navBarData?.footerData?.socialMediaLogosWithLinks
                        ?.instagramLink
                    : ''
                }
                className="pb-2 block"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="22"
                  height="22"
                  viewBox="0 0 26 26"
                  fill="#fff"
                >
                  <path d="M 7.546875 0 C 3.390625 0 0 3.390625 0 7.546875 L 0 18.453125 C 0 22.609375 3.390625 26 7.546875 26 L 18.453125 26 C 22.609375 26 26 22.609375 26 18.453125 L 26 7.546875 C 26 3.390625 22.609375 0 18.453125 0 Z M 7.546875 2 L 18.453125 2 C 21.527344 2 24 4.46875 24 7.546875 L 24 18.453125 C 24 21.527344 21.53125 24 18.453125 24 L 7.546875 24 C 4.472656 24 2 21.53125 2 18.453125 L 2 7.546875 C 2 4.472656 4.46875 2 7.546875 2 Z M 20.5 4 C 19.671875 4 19 4.671875 19 5.5 C 19 6.328125 19.671875 7 20.5 7 C 21.328125 7 22 6.328125 22 5.5 C 22 4.671875 21.328125 4 20.5 4 Z M 13 6 C 9.144531 6 6 9.144531 6 13 C 6 16.855469 9.144531 20 13 20 C 16.855469 20 20 16.855469 20 13 C 20 9.144531 16.855469 6 13 6 Z M 13 8 C 15.773438 8 18 10.226563 18 13 C 18 15.773438 15.773438 18 13 18 C 10.226563 18 8 15.773438 8 13 C 8 10.226563 10.226563 8 13 8 Z"></path>
                </svg>
              </Link>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={
                  navBarData?.footerData?.socialMediaLogosWithLinks
                    ?.linkedinLink
                    ? navBarData?.footerData?.socialMediaLogosWithLinks
                        ?.linkedinLink
                    : ''
                }
                className="pb-2 block"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="22"
                  height="22"
                  viewBox="0 0 26 26"
                  fill="#fff"
                >
                  <path d="M 21.125 0 L 4.875 0 C 2.183594 0 0 2.183594 0 4.875 L 0 21.125 C 0 23.816406 2.183594 26 4.875 26 L 21.125 26 C 23.816406 26 26 23.816406 26 21.125 L 26 4.875 C 26 2.183594 23.816406 0 21.125 0 Z M 8.039063 22.070313 L 4 22.070313 L 3.976563 9.976563 L 8.015625 9.976563 Z M 5.917969 8.394531 L 5.894531 8.394531 C 4.574219 8.394531 3.722656 7.484375 3.722656 6.351563 C 3.722656 5.191406 4.601563 4.3125 5.945313 4.3125 C 7.289063 4.3125 8.113281 5.191406 8.140625 6.351563 C 8.140625 7.484375 7.285156 8.394531 5.917969 8.394531 Z M 22.042969 22.070313 L 17.96875 22.070313 L 17.96875 15.5 C 17.96875 13.910156 17.546875 12.828125 16.125 12.828125 C 15.039063 12.828125 14.453125 13.558594 14.171875 14.265625 C 14.066406 14.519531 14.039063 14.867188 14.039063 15.222656 L 14.039063 22.070313 L 9.945313 22.070313 L 9.921875 9.976563 L 14.015625 9.976563 L 14.039063 11.683594 C 14.5625 10.875 15.433594 9.730469 17.519531 9.730469 C 20.105469 9.730469 22.039063 11.417969 22.039063 15.046875 L 22.039063 22.070313 Z"></path>
                </svg>
              </Link>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default NavigationBar;
