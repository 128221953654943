import { useEffect, useState, useRef } from 'react';
import {
  getProjectFiltersData,
  getProjectListingData,
} from '../../../apiservices/ApiService';
import { getLanguage } from '../../../constants/GeneralConstants';
import ProjectCard from './ProjectCard';
import Pagination from '../../../components/Pagination';
import { useParams } from 'react-router-dom';
import '../../../css/componentscss/Pagination.css';
import '../../../css/pagescss/ProjectsPage.css';
import Loader from '../../../components/Loader';
import NoDataFound from '../../../components/NoDataFound';

const ProjectsList = ({ data, handleTeamClick }) => {
  const { lang } = useParams();

  const filterPayloadObj = {
    lang: getLanguage(),
    sorting: 'N2O',
    services: [],
    dimensions: [],
    style: [],
    genre: [],
    platform: [],
    pageno: 1,
    recordsperpage: 8,
  };
  const [projectListingData, setProjectListingData] = useState();
  const [projectFiltersData, setProjectFiltersData] = useState();
  const [showAlphbetToggle, setShowAlphabetToggle] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [appliedFilters, setAppliedFilters] = useState();
  const [orderFilter, setOrderFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [openedFilter, setOpenedFilter] = useState('');
  const filterRef = useRef();
  const orderRef = useRef();
  const fetchProjectListingData = async (filters) => {
    const projectListingDataResponse = await getProjectListingData(filters);
    setTotalPages(
      Math.ceil(projectListingDataResponse?.data?.totalrecords / 8),
    );
    setProjectListingData(projectListingDataResponse?.data);
    setProjectsLoading(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      const projectFiltersData = await getProjectFiltersData(getLanguage());

      setProjectFiltersData(projectFiltersData);
    };
    fetchData();
    fetchProjectListingData({ ...filterPayloadObj, lang: getLanguage() });

    setAppliedFilters({ ...filterPayloadObj, lang: getLanguage() });
    if (projectListingData?.projects?.length) setProjectsLoading(false);
  }, [lang]);
  useEffect(() => {
    setOrderFilter(data?.projects?.newestToOldest);
  }, [data?.projects?.newestToOldest]);
  const handleSubfilterClick = (e, subFilter) => {
    setCurrentPage(1);
    const classes = ['text-primary', 'border-primary'];
    classes.forEach((cls) => e.target.classList.toggle(cls));
    const tempFilters = { ...appliedFilters, pageno: 1 };

    if (tempFilters[selectedFilter?.toLowerCase()]?.includes(subFilter)) {
      tempFilters[selectedFilter?.toLowerCase()]?.splice(
        tempFilters[selectedFilter?.toLowerCase()]?.indexOf(subFilter),
        1,
      );
    } else {
      tempFilters[selectedFilter?.toLowerCase()].push(subFilter);
    }
    setAppliedFilters(tempFilters);
    fetchProjectListingData(tempFilters);
  };
  const handleOrderFilterClick = (order, orderFilter) => {
    setCurrentPage(1);
    setOrderFilter(orderFilter);
    const tempFilters = {
      ...appliedFilters,
      pageno: 1,
      sorting:
        order === 'ALPHABETICAL ORDER'
          ? 'ALPHA'
          : order === 'NEWEST TO OLDEST'
            ? 'N2O'
            : 'O2N',
    };
    setAppliedFilters(tempFilters);
    fetchProjectListingData(tempFilters);
  };
  const handleOutSideClick = (e) => {
    if (
      (filterRef.current &&
        !filterRef.current.contains(e.target) &&
        !e.target.classList.contains('sub-filter-cls')) ||
      e.target.classList.contains('reset-class') ||
      e.target.classList.contains('mobile-order')
    ) {
      setSelectedFilter(undefined);
      setOpenedFilter(undefined);
    }

    if (
      orderRef.current &&
      !orderRef.current.contains(e.target) &&
      e.target.id !== 'hearAboutUs' &&
      !e.target.classList.contains('mobile-order')
    ) {
      setShowAlphabetToggle(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleOutSideClick, false);
    return () => {
      document.removeEventListener('click', handleOutSideClick, false);
    };
  }, [filterRef, orderRef]);
  const onPaginationChange = (page) => {
    setProjectsLoading(true);
    setCurrentPage(page);

    const tempFilters = { ...appliedFilters, pageno: page };
    fetchProjectListingData(tempFilters);
    setAppliedFilters(tempFilters);
    setTimeout(() => {
      handleTeamClick();
    }, 100);
  };
  return (
    <>
      <section className="mt-12 relative container lg:mt-32 project-pills-container mx-auto">
        <p className="uppercase text-primary text-xs lg:text-base font-medium ">
          {data?.projects?.allProjects}
        </p>
        <div className="block lg:flex uppercase mt-8 justify-between  ">
          {/* filter categories starts here */}

          <div
            className="flex flex-wrap lg:flex-nowrap gap-4 lg:gap-4 xl:gap-6 2xl:gap-8"
            ref={filterRef}
          >
            <p
              className={`text-[14px] lg:text-xl 2xl:text-[20px] font-medium cursor-pointer tracking-[1.5px] ${openedFilter === data?.projects?.services || appliedFilters?.services?.some((value) => projectFiltersData?.project?.services.map((i) => i.id).includes(value)) ? 'text-primary' : ''} `}
              onClick={() => {
                if (selectedFilter === 'services') {
                  setSelectedFilter(undefined);
                  setOpenedFilter(undefined);

                  return;
                }
                setSelectedFilter('services');
                setOpenedFilter(data?.projects?.services);
              }}
            >
              {data?.projects?.services}
            </p>
            <p
              className={`text-[14px] lg:text-xl 2xl:text-[20px] font-medium cursor-pointer tracking-[1.5px] ${openedFilter === data?.projects?.dimensions || appliedFilters?.dimensions?.some((value) => projectFiltersData?.project?.dimensions.map((i) => i.id).includes(value)) ? 'text-primary' : ''}`}
              onClick={() => {
                if (selectedFilter === 'dimensions') {
                  setSelectedFilter(undefined);
                  setOpenedFilter(undefined);

                  return;
                }
                setSelectedFilter('dimensions');
                setOpenedFilter(data?.projects?.dimensions);
              }}
            >
              {data?.projects?.dimensions}
            </p>
            <p
              className={`text-[14px] lg:text-xl 2xl:text-[20px] font-medium cursor-pointer tracking-[1.5px] ${openedFilter === data?.projects?.style || appliedFilters?.style?.some((value) => projectFiltersData?.project?.style.map((i) => i.id).includes(value)) ? 'text-primary' : ''} tracking-[1.5px]`}
              onClick={() => {
                if (selectedFilter === 'style') {
                  setSelectedFilter(undefined);
                  setOpenedFilter(undefined);

                  return;
                }
                setSelectedFilter('style');
                setOpenedFilter(data?.projects?.style);
              }}
            >
              {data?.projects?.style}
            </p>
            <p
              className={`text-[14px] lg:text-xl 2xl:text-[20px] font-medium cursor-pointer tracking-[1.5px] ${openedFilter === data?.projects?.genre || appliedFilters?.genre?.some((value) => projectFiltersData?.project?.genre.map((i) => i.id).includes(value)) ? 'text-primary' : ''}`}
              onClick={() => {
                if (selectedFilter === 'genre') {
                  setSelectedFilter(undefined);
                  setOpenedFilter(undefined);

                  return;
                }
                setSelectedFilter('genre');
                setOpenedFilter(data?.projects?.genre);
              }}
            >
              {data?.projects?.genre}
            </p>
            <p
              className={`text-[14px] lg:text-xl 2xl:text-[20px] font-medium cursor-pointer tracking-[1.5px] ${openedFilter === data?.projects?.platform || appliedFilters?.platform?.some((value) => projectFiltersData?.project?.platform.map((i) => i.id).includes(value)) ? 'text-primary' : ''}`}
              onClick={() => {
                if (selectedFilter === 'platform') {
                  setSelectedFilter(undefined);
                  setOpenedFilter(undefined);
                  return;
                }
                setSelectedFilter('platform');
                setOpenedFilter(data?.projects?.platform);
              }}
            >
              {data?.projects?.platform}
            </p>
            <p
              className={`reset-class text-[14px] lg:text-xl 2xl:text-[20px] font-medium cursor-pointer tracking-[1.5px] active:text-primary`}
              onClick={() => {
                setOpenedFilter(undefined);
                setSelectedFilter(undefined);
                setProjectsLoading(true);
                setAppliedFilters(filterPayloadObj);
                setOrderFilter(data?.projects?.newestToOldest);
                fetchProjectListingData({
                  ...filterPayloadObj,
                  pageno: 1,
                });
                setCurrentPage(1);
              }}
            >
              {data?.projects?.reset}
            </p>
            {/* sorting filter mobile starts here*/}
            <div
              className="block lg:hidden -mt-2 ml-0 lg:ml-[0.7rem] w-[9.6rem] text-left lg:text-right mobile-order"
              ref={orderRef}
              onClick={() => {
                setShowAlphabetToggle(!showAlphbetToggle);
              }}
            >
              <p className="uppercase text-[#68ffd2] text-xs lg:text-base font-medium cursor-pointer inline-block mobile-order">
                {orderFilter}
              </p>
              {/* <img
                className="inline w-4 h-6 ml-2 mt-3  cursor-pointer mobile-order"
                src="/art/images/arrow-team.png"
                alt="arrow img"
              /> */}
              <svg
                width="24"
                height="24"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mt-2 cursor-pointer inline"
              >
                <path
                  d="M8.125 13.75L11.875 17.5L15.625 13.75"
                  stroke="#68ffd2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                  stroke="#68ffd2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              {showAlphbetToggle && (
                <div className="block lg:hidden border rounded-sm order-border p-4 absolute top-32 md:top-24 right-[15px] bg-black z-[2] text-center w-[10rem]">
                  <p
                    className={`text-[12px]  cursor-pointer  alpha-border py-2 ${appliedFilters?.sorting === 'ALPHA' ? 'text-[#68ffd2]' : ''} hover:text-[#68ffd2]`}
                    onClick={() => {
                      if (orderFilter !== data?.projects?.alphabeticalOrder) {
                        setProjectsLoading(true);
                        handleOrderFilterClick(
                          'ALPHABETICAL ORDER',
                          data?.projects?.alphabeticalOrder,
                        );
                      }
                    }}
                  >
                    {data?.projects?.alphabeticalOrder}
                  </p>
                  <p
                    className={`text-[12px] cursor-pointer alpha-border py-2 ${appliedFilters?.sorting === 'N2O' ? 'text-[#68ffd2]' : ''} hover:text-[#68ffd2]`}
                    onClick={() => {
                      if (orderFilter !== data?.projects?.newestToOldest) {
                        setProjectsLoading(true);

                        handleOrderFilterClick(
                          'NEWEST TO OLDEST',
                          data?.projects?.newestToOldest,
                        );
                      }
                    }}
                  >
                    {data?.projects?.newestToOldest}
                  </p>
                  <p
                    className={`text-[12px] cursor-pointer  pt-2 ${appliedFilters?.sorting === 'O2N' ? 'text-[#68ffd2]' : ''} hover:text-[#68ffd2]`}
                    onClick={() => {
                      if (orderFilter !== data?.projects?.oldestToNewest) {
                        setProjectsLoading(true);
                        handleOrderFilterClick(
                          'OLDEST TO NEWEST',
                          data?.projects?.oldestToNewest,
                        );
                      }
                    }}
                  >
                    {data?.projects?.oldestToNewest}
                  </p>
                </div>
              )}
            </div>
            {/* sorting filter mobile ends here*/}
          </div>
          {/* filter categories ends here */}
          {/* sorting filter desktop starts here*/}

          <div
            className="hidden lg:flex justify-end relative float-right"
            ref={orderRef}
            onClick={() => {
              setShowAlphabetToggle(!showAlphbetToggle);
            }}
          >
            <p className="uppercase text-[##68ffd2] text-xs lg:text-base font-medium cursor-pointer inline-block">
              {orderFilter}
            </p>
            {/* <img
              className="inline w-4 h-6 ml-2 mt-3  cursor-pointer"
              src="/art/images/arrow-team.png"
              alt="arrow img"
            /> */}
            <svg
              width="24"
              height="24"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mt-2 cursor-pointer inline"
            >
              <path
                d="M8.125 13.75L11.875 17.5L15.625 13.75"
                stroke="#68ffd2"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                stroke="#68ffd2"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            {showAlphbetToggle && (
              <div className="hidden lg:block border rounded-sm order-border p-4 absolute top-12 -right-[0.5px] bg-black z-[2] text-center w-[13rem]">
                <p
                  className={`text-base cursor-pointer  alpha-border py-2 ${appliedFilters?.sorting === 'ALPHA' ? 'text-[#68ffd2]' : ''} hover:text-[#68ffd2]`}
                  onClick={() => {
                    if (orderFilter !== data?.projects?.alphabeticalOrder) {
                      setProjectsLoading(true);
                      handleOrderFilterClick(
                        'ALPHABETICAL ORDER',
                        data?.projects?.alphabeticalOrder,
                      );
                    }
                  }}
                >
                  {data?.projects?.alphabeticalOrder}
                </p>
                <p
                  className={`text-base cursor-pointer alpha-border py-2 ${appliedFilters?.sorting === 'N2O' ? 'text-[#68ffd2]' : ''} hover:text-[#68ffd2]`}
                  onClick={() => {
                    if (orderFilter !== data?.projects?.newestToOldest) {
                      setProjectsLoading(true);

                      handleOrderFilterClick(
                        'NEWEST TO OLDEST',
                        data?.projects?.newestToOldest,
                      );
                    }
                  }}
                >
                  {data?.projects?.newestToOldest}
                </p>
                <p
                  className={`text-base cursor-pointer  pt-2 ${appliedFilters?.sorting === 'O2N' ? 'text-[#68ffd2]' : ''} hover:text-[#68ffd2]`}
                  onClick={() => {
                    if (orderFilter !== data?.projects?.oldestToNewest) {
                      setProjectsLoading(true);
                      handleOrderFilterClick(
                        'OLDEST TO NEWEST',
                        data?.projects?.oldestToNewest,
                      );
                    }
                  }}
                >
                  {data?.projects?.oldestToNewest}
                </p>
              </div>
            )}
          </div>
          {/* sorting filter desktop ends here*/}
        </div>
        {/*catogories sub filter options starts here*/}
        <div className="mt-3 lg:mt-4 flex lg:gap-6 w-full flex-wrap ">
          {projectFiltersData?.project &&
            projectFiltersData?.project[selectedFilter?.toLowerCase()]?.map(
              (subFilter) => (
                <button
                  key={subFilter?.id}
                  onClick={(e) => {
                    setProjectsLoading(true);
                    handleSubfilterClick(e, subFilter?.id);
                  }}
                  className={`sub-filter-cls text-xs lg:text-base rounded-full border text-[#929292] border-[#929292] py-2 px-4 mr-4 lg:mr-0 mb-4 lg:mb-0 lg:hover:text-primary lg:hover:border-primary uppercase ${appliedFilters[selectedFilter?.toLowerCase()]?.includes(subFilter?.id) ? 'text-primary border-primary' : ''}`}
                >
                  {subFilter?.value}
                </button>
              ),
            )}
        </div>
        {/*catogories sub filter options ends here*/}
      </section>
      {/*projects cards starts here*/}
      {projectsLoading ? (
        <Loader />
      ) : (
        <section
          className={`project-pills-container project-card-container gap-8  mx-auto mt-16 ${projectListingData?.projects?.length > 0 ? '' : '!block'}`}
        >
          {projectListingData?.projects?.length > 0 ? (
            <>
              {' '}
              {projectListingData?.projects?.map((project, index) => (
                <ProjectCard
                  key={project?.title}
                  project={project}
                  data={data}
                  lowQualityImg={
                    project?.bannerImageLowQuality
                      ? project?.bannerImageLowQuality
                      : project?.bannerImage
                  }
                  highQualityImg={project?.bannerImage}
                  index={index}
                />
              ))}
            </>
          ) : (
            <div className="flex justify-center items-center h-12">
              <NoDataFound NoDataFound={data?.projects?.noDataFound} />
            </div>
          )}
        </section>
      )}
      {/*projects cards ends here*/}
      {/*pagination starts here*/}
      {totalPages > 1 && (
        <section className="flex items-center justify-center pagination">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPaginationValueChange={onPaginationChange}
          />
        </section>
      )}
      {/*pagination ends here*/}
    </>
  );
};

export default ProjectsList;
