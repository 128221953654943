// Services Page
import { useEffect, useRef, useState } from 'react';
import Text from '../components/Text';
import '../css/pagescss/ServicesPage.css';
import {
  getLanguage,
  languageCodes,
  navigateTo,
  scrollToTop,
  stringToUpperCase,
} from '../constants/GeneralConstants';
import { useSelector } from 'react-redux';
import Image from '../components/Image';
import TellUsYourStory from '../components/TellUsYourStory';
import RightSlider from '../components/RightSlider';
import { getServicesDataAPI1 } from '../apiservices/ApiService';
import ClientAndPartnersSlider from '../components/ClientAndPartnersSlider';
import { clientUrls } from '../constants/UrlConstants';
import { useNavigate, useParams } from 'react-router-dom';
import YouTubePlayer from '../components/YoutubePlayer';
import Loader from '../components/Loader';
import ServicesSliderArt from '../components/ServicesSliderArt';
import Footer from '../components/Footer';
import ArtSiteSEO from '../components/ArtSiteSEO';
import PILImg from '../components/PILImg';
import AnimateOnScroll from '../components/AnimationScroll';
import AOS from 'aos';
import PageNotFound from '../components/PageNotFound';

const ServicesPage = () => {
  const { lang } = useParams();
  const data = useSelector((state) => state.language.selectedLanguage);
  const navigate = useNavigate();
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const [servicesData1, setServicesData1] = useState([]);
  const [servicesData2, setServicesData2] = useState([]);
  const [servicesData3, setServicesData3] = useState([]);
  const [loader, setLoader] = useState(true);
  const [playingVideo, setPlayingVideo] = useState(null);

  const handlePlay = (videoId) => {
    setPlayingVideo(videoId);
  };

  useEffect(() => {
    scrollToTop();
    callSection1APIToGetData(getLanguage());
  }, [data]);

  const callSection1APIToGetData = async (language) => {
    try {
      const res = await getServicesDataAPI1(language);
      setServicesData1(res?.data?.services?.service1);
      setServicesData2(res?.data?.services?.service2);
      setServicesData3(res?.data?.services?.service3);
      setLoader(false);
      setTimeout(() => {
        AOS.refresh();
      }, 500);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNavigateToGameArtServices = () => {
    let element = document.getElementById('gameArtServicesWeb');
    element.scrollIntoView({ behavior: 'smooth' });
  };
  if (lang && !languageCodes.includes(lang)) {
    return <PageNotFound />;
  }
  return (
    <>
      <>
        <ArtSiteSEO
          title={servicesData1?.seo?.title}
          description={servicesData1?.seo?.description}
          url={
            getLanguage() !== 'en'
              ? `/${getLanguage()}${clientUrls.services}`
              : `${clientUrls.services}`
          }
        />
        {loader ? (
          <Loader />
        ) : (
          <>
            <section id="services-page" className="pt-16 lg:pt-24">
              {servicesData1?.topBanner && (
                // services banner with heading and content section starts here
                <div ref={section1Ref} className="service-s">
                  <div className="services-heading w-[90%] lg:w-[80%] mx-auto my-12 lg:my-30">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                      <div className="text-center lg:text-left mt-9">
                        <h2 className="font-extrabold text-4xl md:text-6xl lg:text-[60px] xl:text-[80px] 2xl:text-[98px]   uppercase p-1">
                          <AnimateOnScroll animation="fade-up" duration={1000}>
                            <Text
                              className="block ml-12 lg:ml-4"
                              title={`${servicesData1?.topBanner?.art} ${' '} ${servicesData1?.topBanner?.that}`}
                              titleTag="span"
                            />
                          </AnimateOnScroll>
                          <AnimateOnScroll animation="fade-up" duration={1000}>
                            <Text
                              className="block relative  ml-4 lg:left-[-17%] left-[-10%]"
                              title={servicesData1?.topBanner?.makesA}
                              titleTag="span"
                            />
                          </AnimateOnScroll>
                          <AnimateOnScroll animation="fade-up" duration={1000}>
                            <Text
                              className="block  ml-16 xs:ml-20 lg:-ml-4"
                              title={servicesData1?.topBanner?.statement}
                              titleTag="span"
                            />
                          </AnimateOnScroll>
                        </h2>
                        <div className="mt-5 ml-12 lg:ml-0">
                          <span className="block bg-white h-[.1rem] w-4  lg:w-7 opacity-[50%] mb-2 lg:mb-3 mr-[0.5rem] text-[5rem]"></span>
                          <AnimateOnScroll animation="fade-up" duration={1000}>
                            <p className="uppercase font-medium text-[12px] lg:text-lg xl:text-xl 2xl:text-2xl text-left">
                              {servicesData1?.topBanner?.bringingYourArtToLife}
                            </p>
                          </AnimateOnScroll>
                          <AnimateOnScroll animation="fade-up" duration={1000}>
                            <p className="uppercase font-medium text-[12px] lg:text-lg xl:text-xl 2xl:text-2xl text-left">
                              {
                                servicesData1?.topBanner
                                  ?.bringingYourStoryToTheWorld
                              }
                            </p>
                          </AnimateOnScroll>
                        </div>
                      </div>
                      <div className="flex justify-center items-center corner-images shadow-bg my-8 lg:my-20 relative px-3 py-2">
                        <div className="hidden lg:block">
                          <div className="shadow-services"></div>
                        </div>
                        <div className="w-full hidden1 lg:block">
                          <AnimateOnScroll animation="zoom-in">
                            <PILImg
                              src={servicesData1?.topBanner?.bannerImage}
                              className="w-[15rem] ml-auto lg:w-full lg:ml-0"
                              alt="Ptw art"
                              lowQaulitySrc={
                                servicesData1?.topBanner?.bannerImageLowQuality
                                  ? servicesData1?.topBanner
                                      ?.bannerImageLowQuality
                                  : servicesData1?.topBanner?.bannerImage
                              }
                            />
                          </AnimateOnScroll>
                        </div>
                        {/* <div className="w-full block lg:hidden">
                          <AnimateOnScroll animation="zoom-in">
                            <PILImg
                              // src="/art/images/satyr-goat-mobile.png"
                              src={servicesData1?.topBanner?.bannerImage}
                              className="w-[15rem] float-right"
                              alt="Ptw art"
                              lowQaulitySrc={
                                servicesData1?.topBanner?.bannerImageLowQuality
                                  ? servicesData1?.topBanner
                                      ?.bannerImageLowQuality
                                  : servicesData1?.topBanner?.bannerImage
                              }
                            />
                          </AnimateOnScroll>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div
                    id="services-text"
                    className="w-[90%] lg:w-[70%] mx-auto my-12 lg:my-20"
                  >
                    <p className="pb-4 lg:pb-8 text-[16px] lg:text-[20px]">
                      {' '}
                      {servicesData1?.topBanner?.paragraph1}
                    </p>
                    <p className="pb-4 lg:pb-8 text-[16px] lg:text-[20px]">
                      {servicesData1?.topBanner?.paragraph2}
                    </p>
                    <p className="pb-4 lg:pb-8 text-[16px] lg:text-[20px]">
                      {servicesData1?.topBanner?.paragraph3}
                    </p>
                    <div className="text-left lg:text-center mt-3 mb-24">
                      <div
                        className="mt-6 inline-flex items-center hover-bg "
                        onClick={(e) => {
                          navigate(navigateTo(clientUrls.contact));
                        }}
                      >
                        <button
                          className="border pr-[3rem] pl-[2rem] overflow-hidden relative rounded-[50px] uppercase h-11 vs:text-[14px] xs:text-[16px]"
                          name=""
                        >
                          {servicesData1?.topBanner?.getInTouchButton}
                        </button>
                        <span className="arrowcircle flex bg-black justify-center items-center border relative overflow-hidden ml-[-2.5rem] w-16 h-11 rounded-full cursor-pointer hover:bg-red-700">
                          <svg
                            width="16"
                            height="11"
                            viewBox="0 0 16 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                              fill="white"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                // services banner with heading and content section ends here
              )}
              {servicesData1?.topBanner && (
                <div className="relative">
                  <div id="partner-logos" className="">
                    <AnimateOnScroll animation="fade-up" duration={1000}>
                      <Text
                        className="hidden lg:block uppercase text-primary lg:text-[70px] md:text-6xl 2xl:text-[110px] xl:text-[85px] font-extrabold opacity-[25%] pb-8 lg:pb-12 text-center"
                        title={servicesData1?.proudPartnership}
                        titleTag="h1"
                      />
                    </AnimateOnScroll>
                    <div className="desktop-client-logos hidden lg:block p-5">
                      <div className="shadows">
                        <Image
                          alt="Side"
                          className="leftShadow"
                          src="/art/images/home-left-shadow.png"
                        />
                        <Image
                          alt="Side"
                          className="rightShadow"
                          src="/art/images/home-right-shadow.png"
                        />
                      </div>
                      <div className="logos">
                        {servicesData1?.clientsLogo?.length > 0 ? (
                          <ClientAndPartnersSlider
                            slides={servicesData1?.clientsLogo}
                          />
                        ) : null}
                      </div>
                    </div>
                    <AnimateOnScroll animation="fade-up" duration={1000}>
                      {servicesData1?.proudPartnership
                        ?.split(' ')
                        .map((el, i) => (
                          <Text
                            key={i}
                            className="block lg:hidden head-proud w-[90%] mx-auto text-[37px] md:text-[70px] font-extrabold opacity-[25%] text-center uppercase text-primary"
                            title={stringToUpperCase(
                              el ? el : i === 0 ? 'Proud' : 'Partnership',
                            )}
                            titleTag="h2"
                          />
                        ))}
                    </AnimateOnScroll>
                    <div className="mobile-client-logos block lg:hidden absolute vs:top-[-15%] xs:top-[-20%] sm:top-[-50%] md:top-[-10%] width-fill mobile-top w-full">
                      <div className="shadows">
                        <Image
                          alt="Side"
                          className="leftShadow w-5rem"
                          src="/art/images/home-left-shadow.png"
                        />
                        <Image
                          alt="Side"
                          className="rightShadow w-5rem"
                          src="/art/images/home-right-shadow.png"
                        />
                      </div>
                      <div className="logos">
                        {servicesData1?.clientsLogo?.length > 0 ? (
                          <ClientAndPartnersSlider
                            slides={servicesData1?.clientsLogo}
                            className="partner-slider"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div ref={section2Ref}>
                {servicesData2?.['3DCharacters'] && (
                  <>
                    <div className="mt-5 mb-12 lg:mt-10 lg:mb-24">
                      <div className="border_line hidden lg:block">
                        <div className="shadows">
                          <Image
                            src="/art/images/home-left-shadow.png"
                            alt="Side"
                            className="object-cover leftShadow"
                          />
                          <Image
                            src="/art/images/home-right-shadow.png"
                            alt="Side"
                            className="object-cover rightShadow"
                          />
                        </div>
                        <Image
                          src="/art/images/line-client.png"
                          alt={'Ptw art'}
                        />
                      </div>
                    </div>
                    {/* 3d character section starts here  */}
                    <div className="3d-char lg:mt-40">
                      <AnimateOnScroll animation="zoom-in">
                        {servicesData2?.['3DCharacters']?.[
                          '3DCharacterVideoURL'
                        ] ? (
                          <YouTubePlayer
                            videoId={
                              servicesData2?.['3DCharacters']?.[
                                '3DCharacterVideoURL'
                              ]
                                ? servicesData2?.['3DCharacters']?.[
                                    '3DCharacterVideoURL'
                                  ]
                                : '3JDr7GJiDXI'
                            }
                            playingVideo={playingVideo}
                            onPlay={handlePlay}
                          />
                        ) : (
                          ''
                        )}
                      </AnimateOnScroll>
                    </div>
                    {/* 3d character section ends here  */}
                    {/* game art services with image section starts here  */}
                    <div
                      className="game-services w-[100%] lg:w-[80%] lg:mx-auto my-12 lg:my-24"
                      id="gameArtServicesWeb"
                    >
                      <div className="flex lg:flex-row flex-col-reverse w-full gap-0 lg:gap-12 justify-between">
                        <div className="lg:mt-20">
                          <div className="hidden lg:block">
                            <AnimateOnScroll
                              animation="fade-up"
                              duration={1000}
                            >
                              <Text
                                className="text-left uppercase lg:text-3xl md:text-3xl 2xl:text-5xl xl:text-3xl font-extrabold"
                                title={servicesData2?.['3DCharacters']?.game}
                                titleTag="h2"
                              />
                            </AnimateOnScroll>
                            <div className="flex gap-2">
                              <AnimateOnScroll
                                animation="fade-up"
                                duration={1000}
                              >
                                <Text
                                  className="text-left uppercase lg:text-3xl md:text-3xl 2xl:text-5xl xl:text-3xl font-extrabold hidden lg:block"
                                  title={
                                    servicesData2?.['3DCharacters']?.artServices
                                  }
                                  titleTag="h2"
                                />
                              </AnimateOnScroll>
                              <AnimateOnScroll
                                animation="fade-up"
                                duration={1000}
                              >
                                <Text
                                  className="text-left uppercase text-3xl lg:text-5xl font-extrabold block lg:hidden"
                                  title={
                                    servicesData2?.['3DCharacters']
                                      ?.gameArtServices
                                  }
                                  titleTag="h2"
                                />
                              </AnimateOnScroll>
                              <div
                                className="pb-10 md:pb-10 cursor-pointer"
                                onClick={(e) =>
                                  handleNavigateToGameArtServices(
                                    'gameArtServicesWeb',
                                  )
                                }
                              >
                                <Image
                                  src="/art/images/arrow-team.png"
                                  alt="Image"
                                  className="h-auto w-5 lg:w-7 mt-6 ml-2"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mx-8 lg:mx-0 my-8 lg:my-0">
                            <span className="block bg-white h-[.1rem] w-[1rem] opacity-[50%] mb-[0.3rem] mr-[0.5rem] text-[5rem]"></span>
                            <ul className="list-none uppercase mt-1 mb-6 font-medium text-[16px] lg:text-[18px]">
                              {servicesData2?.[
                                '3DCharacters'
                              ]?.gameArtServicesList.map((service, index) => {
                                return (
                                  <li key={index}>{service.serviceName}</li>
                                );
                              })}
                            </ul>
                          </div>
                          <div className="mt-3 mb-12 lg:mb-24 hidden lg:block">
                            <div
                              className="pt-2 mt-4 inline-flex items-center hover-bg"
                              onClick={(e) =>
                                navigate(navigateTo(clientUrls.projects))
                              }
                            >
                              <button
                                className="border pr-[3rem] pl-[2rem] overflow-hidden relative rounded-[50px] uppercase h-11 vs:text-[14px] xs:text-[16px]"
                                name=""
                              >
                                {
                                  servicesData2?.['3DCharacters']
                                    ?.seeOurProjectsButton
                                }
                              </button>
                              <span className="arrowcircle flex bg-black justify-center items-center border relative overflow-hidden ml-[-2.25rem] w-16 h-11 rounded-full cursor-pointer hover:bg-red-700">
                                <svg
                                  width="16"
                                  height="11"
                                  viewBox="0 0 16 11"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                                    fill="white"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                          </div>

                          <div className="mt-3 mb-12 lg:mb-24 block lg:hidden mx-9 lg:mx-0 text-left lg:text-center">
                            <div
                              className="pt-2 mt-4 inline-flex items-center hover-bg"
                              onClick={(e) =>
                                navigate(navigateTo(clientUrls.projects))
                              }
                            >
                              <button
                                className="border pr-[3rem] pl-[2rem] overflow-hidden relative rounded-[50px] uppercase h-11 vs:text-[14px] xs:text-[16px]"
                                name=""
                              >
                                {
                                  servicesData2?.['3DCharacters']
                                    ?.seeOurProjectsButton
                                }
                              </button>
                              <span className="arrowcircle flex bg-black justify-center items-center border relative overflow-hidden ml-[-2.5rem] w-16 h-11 rounded-full cursor-pointer hover:bg-red-700">
                                <svg
                                  width="16"
                                  height="11"
                                  viewBox="0 0 16 11"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                                    fill="white"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="corner-images flex justify-center mx-4 lg:mx-0 px-3 py-2 relative">
                          <div className="hidden lg:hidden 2xl:block md:hidden">
                            <div className="shadow-services shadow-serv"></div>
                          </div>
                          <AnimateOnScroll animation="zoom-in">
                            <PILImg
                              src={
                                servicesData2?.['3DCharacters']
                                  ?.gameArtServicesBannerImage
                              }
                              alt="Ptw art"
                              className="h-full w-full hidden lg:block"
                              lowQaulitySrc={
                                servicesData2?.['3DCharacters']
                                  ?.gameArtServicesBannerImageLowQuality
                                  ? servicesData2?.['3DCharacters']
                                      ?.gameArtServicesBannerImageLowQuality
                                  : servicesData2?.['3DCharacters']
                                      ?.gameArtServicesBannerImage
                              }
                            />
                          </AnimateOnScroll>
                          <AnimateOnScroll animation="zoom-in">
                            <PILImg
                              src="/art/images/nikita-mobile.png"
                              alt="Ptw art"
                              className="h-auto w-auto block lg:hidden"
                              lowQaulitySrc={
                                servicesData2?.['3DCharacters']
                                  ?.gameArtServicesBannerImageLowQuality
                                  ? servicesData2?.['3DCharacters']
                                      ?.gameArtServicesBannerImageLowQuality
                                  : servicesData2?.['3DCharacters']
                                      ?.gameArtServicesBannerImage
                              }
                            />
                          </AnimateOnScroll>
                        </div>

                        <div className="block lg:hidden">
                          <div className="flex items-center justify-center">
                            <AnimateOnScroll
                              animation="fade-up"
                              duration={1000}
                            >
                              <Text
                                className="text-white text-2xl uppercase font-extrabold pb-10 md:pb-10 "
                                title={
                                  servicesData2?.['3DCharacters']
                                    ?.gameArtServices
                                }
                                titleTag="h2"
                              />
                            </AnimateOnScroll>
                            <div
                              className="pb-10 cursor-pointer"
                              onClick={(e) => handleNavigateToGameArtServices()}
                            >
                              <Image
                                src="/art/images/arrow-team.png"
                                alt="Image"
                                className="h-auto w-5 mt-4 ml-2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* game art services with image section ends here  */}
                    {/* Showreel video section starts here  */}
                    <div className="video lg:mt-40" ref={section3Ref}>
                      <AnimateOnScroll animation="zoom-in">
                        {servicesData2?.['3DCharacters']?.[
                          'gameArtServicesVideoURL'
                        ] ? (
                          <YouTubePlayer
                            videoId={
                              servicesData2?.['3DCharacters']?.[
                                'gameArtServicesVideoURL'
                              ]
                                ? servicesData2?.['3DCharacters']?.[
                                    'gameArtServicesVideoURL'
                                  ]
                                : 'lm5fQj5_soM'
                            }
                            playingVideo={playingVideo}
                            onPlay={handlePlay}
                          />
                        ) : (
                          ''
                        )}
                      </AnimateOnScroll>
                    </div>
                    {/* Showreel video section ends here  */}
                    {/* UI UX mobile section starts here  */}
                    <div className="block lg:hidden">
                      {servicesData2?.['3DCharacters']?.gameArtImagesForMobile
                        .length > 0 ? (
                        <ServicesSliderArt
                          slideArray={
                            servicesData2?.['3DCharacters']
                              ?.gameArtImagesForMobile
                          }
                        />
                      ) : null}
                    </div>
                    {/* UI UX mobile section ends here  */}
                    {/* UI UX desktop section starts here  */}
                    <div className="flex justify-center items-center lg:mt-14">
                      <div className="my-12 lg:my-20 w-[80%] mx-auto hidden lg:block">
                        <Image
                          src={servicesData2?.['3DCharacters']?.gameArtImageURL}
                          alt="Ptw art"
                          className="w-full"
                        />
                      </div>
                    </div>
                    {/* UI UX desktop section ends here  */}
                    {/* we have 300+ content para section starts here  */}
                    <div className="w-[90%] lg:w-[80%] mx-auto mb-12 lg:mb-32">
                      <Text
                        className="text-left font-normal text-[16px] lg:text-[20px]"
                        title={
                          servicesData2?.['3DCharacters']
                            ?.gameArtServicesContent
                        }
                        titleTag="p"
                      />
                    </div>{' '}
                    {/* we have 300+ content para section ens here  */}
                  </>
                )}
              </div>
              <div>
                {servicesData3?.['3DEnvironment'] && (
                  <>
                    <div className="3d-env">
                      <AnimateOnScroll animation="zoom-in">
                        {servicesData3?.['3DEnvironment']?.[
                          '3DEnvironmentVideoURL'
                        ] ? (
                          <YouTubePlayer
                            videoId={
                              servicesData3?.['3DEnvironment']?.[
                                '3DEnvironmentVideoURL'
                              ]
                                ? servicesData3?.['3DEnvironment']?.[
                                    '3DEnvironmentVideoURL'
                                  ]
                                : 'T4OgN--3Nfc'
                            }
                            playingVideo={playingVideo}
                            onPlay={handlePlay}
                          />
                        ) : (
                          ''
                        )}
                      </AnimateOnScroll>
                    </div>
                    {/* 3d environment video section ends here  */}

                    {/* Vertical carousel/ slider section starts here  */}
                    <div className="vertical-carousel mt-10 lg:mt-16 lg:py-20 py-8">
                      <div className="flex lg:flex-row flex-col-reverse w-full gap-8 2xl:gap-0 ">
                        <div className="flex items-center justify-end mt-8 lg:mt-0">
                          <div className=" flex flex-col justify-around h-full gap-16">
                            <div className="w-[90%] mx-auto lg:w-[80%] lg:ml-auto lg:mr-0">
                              <p className="text-[14px] lg:text-[20px] leading-6 lg:leading-10">
                                {
                                  servicesData3?.['3DEnvironment']?.[
                                    '3DEnvironmentParagraph1'
                                  ]
                                }
                              </p>
                              <p className="text-[14px] lg:text-[20px] leading-6 lg:leading-10">
                                {
                                  servicesData3?.['3DEnvironment']?.[
                                    '3DEnvironmentParagraph2'
                                  ]
                                }
                              </p>

                              <div className="lg:mt-12 lg:mb-24 mb-12">
                                <div
                                  className="pt-2 mt-4 hidden lg:inline-flex items-center hover-bg"
                                  onClick={(e) =>
                                    navigate(navigateTo(clientUrls.portfolio))
                                  }
                                >
                                  <button
                                    className="border pr-[3rem] pl-[2rem] overflow-hidden relative rounded-[50px] uppercase h-11 vs:text-[14px] xs:text-[16px]"
                                    name=""
                                  >
                                    {
                                      servicesData3?.['3DEnvironment']
                                        ?.seeOurPortfolios
                                    }
                                  </button>
                                  <span className="arrowcircle flex bg-black justify-center items-center border relative overflow-hidden ml-[-2.5rem] w-16 h-11 rounded-full cursor-pointer hover:bg-red-700">
                                    <svg
                                      width="16"
                                      height="11"
                                      viewBox="0 0 16 11"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                                        fill="white"
                                      ></path>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex w-[100%] lg:w-[75%] justify-end relative ml-auto">
                          <div className="flex justify-start lg:justify-end items-center gap-4">
                            {servicesData3?.['3DEnvironment']?.[
                              '3DEnvironmentImages'
                            ]?.length > 0 ? (
                              <RightSlider
                                slideArray={
                                  servicesData3?.['3DEnvironment']?.[
                                    '3DEnvironmentImages'
                                  ]
                                }
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Vertical carousel/ slider section ends here  */}

                    {/* 2 columns cards section starts here  */}
                    <div className="absolute hidden lg:block">
                      <img
                        className="w-full h-full"
                        src="/art/images/cards-shadow.png"
                      />
                    </div>

                    <div className="2-cards w-auto 2xl:w-[70%] lg:w-[80%] mx-[18px] lg:mx-auto my-0 md:my-12 lg:my-20">
                      <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-12 lg:gap-28">
                        {servicesData3?.['3DEnvironment']?.[
                          '3DEnvironmentServices'
                        ]?.length > 0
                          ? servicesData3?.['3DEnvironment']?.[
                              '3DEnvironmentServices'
                            ].map((service, index1) => {
                              return (
                                <div
                                  key={index1}
                                  className="p-10 lg:px-14 lg:py-20 border-gradient-cards bg-black"
                                >
                                  <Text
                                    className="uppercase text-[20px] lg:text-2xl font-extrabold"
                                    title={service.title}
                                    titleTag="h2"
                                  />
                                  <ul className="font-normal text-[16px] p-3 list-disc list-outside li-color">
                                    {service?.services?.map(
                                      (services, index2) => {
                                        return (
                                          <li
                                            key={index2}
                                            className={
                                              index1 === 1 &&
                                              index2 ===
                                                service?.services?.length - 1
                                                ? 'list-none'
                                                : ''
                                            }
                                          >
                                            {services.name}
                                          </li>
                                        );
                                      },
                                    )}
                                  </ul>
                                </div>
                              );
                            })
                          : null}
                      </div>

                      {/* border line  */}
                    </div>
                    {/* 2 columns cards section ends here  */}

                    {/* voxel model video section starts here  */}
                    <div className="voxel mt-14 lg:mt-32">
                      <AnimateOnScroll animation="zoom-in">
                        {servicesData3?.['voxelModels']?.[
                          'voxelModelVideoURL'
                        ] ? (
                          <YouTubePlayer
                            videoId={
                              servicesData3?.['voxelModels']?.[
                                'voxelModelVideoURL'
                              ]
                                ? servicesData3?.['voxelModels']?.[
                                    'voxelModelVideoURL'
                                  ]
                                : 'N7Y0jm8OB8A'
                            }
                            playingVideo={playingVideo}
                            onPlay={handlePlay}
                          />
                        ) : (
                          ''
                        )}
                      </AnimateOnScroll>
                    </div>
                    {/* voxel model video section ends here  */}

                    {/* as a company content with alean image section starts here  */}
                    <div className="as-game w-[90%] lg:w-[80%] mx-auto my-12 lg:my-28">
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <div className="col-span-1 lg:col-span-21 lg:mt-28">
                          <Text
                            className="lg:pb-5 lg:leading-10 text-[16px] lg:text-[20px]"
                            title={servicesData3?.voxelModels?.paragraph1}
                            titleTag="p"
                          />
                          <Text
                            className="lg:leading-10 text-[16px] lg:text-[20px]"
                            title={servicesData3?.voxelModels?.paragraph2}
                            titleTag="p"
                          />
                          <Text
                            className="lg:leading-10 text-primary text-[16px] lg:text-[20px] lg:font-medium lg:pt-7"
                            title={servicesData3?.voxelModels?.paragraph3}
                            titleTag="p"
                          />

                          <div className="flex gap-2"></div>
                        </div>
                        <div className="corner-images1 flex justify-center mt-12 lg:mt-1">
                          <AnimateOnScroll animation="zoom-in">
                            <PILImg
                              src={
                                servicesData3?.voxelModels?.voxelModelImageURL
                              }
                              alt="Ptw art"
                              className="w-full hidden lg:block"
                              lowQaulitySrc={
                                servicesData3?.voxelModels
                                  ?.voxelModelImageURLLowQuality
                                  ? servicesData3?.voxelModels
                                      ?.voxelModelImageURLLowQuality
                                  : servicesData3?.voxelModels
                                      ?.voxelModelImageURL
                              }
                            />
                          </AnimateOnScroll>
                          <AnimateOnScroll animation="zoom-in">
                            <PILImg
                              src="/art/images/alien-mobile.png"
                              alt="Ptw art"
                              className="w-full block lg:hidden"
                              lowQaulitySrc={
                                servicesData3?.voxelModels
                                  ?.voxelModelImageURLLowQuality
                                  ? servicesData3?.voxelModels
                                      ?.voxelModelImageURLLowQuality
                                  : servicesData3?.voxelModels
                                      ?.voxelModelImageURL
                              }
                            />
                          </AnimateOnScroll>
                        </div>
                      </div>
                    </div>

                    <TellUsYourStory />
                    <Footer />
                  </>
                )}
              </div>
            </section>
          </>
        )}
      </>
    </>
  );
};

export default ServicesPage;
