import { useNavigate } from 'react-router-dom';
import { navigateTo } from '../../../constants/GeneralConstants';
import '../../../css/pagescss/ProjectsPage.css';
import { useState } from 'react';
import AnimateOnScroll from '../../../components/AnimationScroll';
import { clientUrls } from '../../../constants/UrlConstants';

const ProjectCard = ({
  project,
  data,
  lowQualityImg,
  highQualityImg,
  index,
}) => {
  const navigate = useNavigate();
  const [src, setSrc] = useState(lowQualityImg);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleOnLoad = (hqImg) => {
    setSrc(hqImg);
    setIsLoaded(true);
  };
  const handlezooming = (e) => {
    document.querySelector(`.project-image-${index}`).style.transform =
      'scale(1.05)';

    e.preventDefault();
  };
  const handlezoomoff = (e) => {
    document.querySelector(`.project-image-${index}`).style.transform =
      'scale(1)';

    e.preventDefault();
  };
  return (
    <>
      <div
        onClick={() =>
          navigate(navigateTo(`${clientUrls.projects}/${project?.projectURL}`))
        }
        className="flex gap-6k mb-8 flex-col uppercase  group image-container projects-slant cursor-pointer"
      >
        <div className="relative">
          <AnimateOnScroll animation="zoom-in">
            <img
              className={`projects-slant-child project-image-${index}`}
              src={src}
              onLoad={() => handleOnLoad(highQualityImg)}
              alt="project listing image "
              style={{ filter: isLoaded ? 'none' : 'blur(7px)' }}
            ></img>
          </AnimateOnScroll>
          <div className="absolute top-0 right-0 h-12 w-12 z-[2] hidden group-hover:flex justify-center items-center rounded-bl-md bg-[#1A1A1A]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="55"
              viewBox="0 0 24 24"
              strokeWidth="3"
              stroke="#68FFD2"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
              />
            </svg>
          </div>
          {/* <Link
            to={
              getLanguage() === 'en'
                ? `/projects/${project?.projectURL}`
                : `/${getLanguage()}/projects/${project?.projectURL}`
            }
          >
            <button
              className="w-[7rem] h-[7rem] bg-primary rounded-[100%] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block transition duration-300 ease-in-out hover-button"
              onMouseLeave={(e) => handlezoomoff(e)}
              onMouseOver={(e) => handlezooming(e)}
            >
              {data?.projects?.view}
              <br />
              {data?.projects?.project}
            </button>
          </Link> */}
        </div>

        {/* <div className="flex justify-between items-baseline bg-[url('/public/images/Rectangle_bottom_right.png')] bg-right-bottom bg-no-repeat"> */}
        <div className="bg-[#46464680] group-hover:bg-[#68FFD2] rounded-b-md backdrop-blur-sm p-6 pb-2 cursor-pointer flex justify-between items-baseline">
          <div>
            <h2 className="text-[26px] font-extrabold line-clamp-1 group-hover:text-[#1A1A1A]">
              {project?.title}
            </h2>
            <p className="text-base leading-[24px] text-[#aeaeae] group-hover:text-[#1A1A1A]">
              {project?.clientName}
            </p>
          </div>
          <div>
            <p className="text-base leading-[24px] text-[#aeaeae] group-hover:text-[#1A1A1A]">
              {' '}
              {project?.releaseYears}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectCard;
