// Router Urls
export const clientUrls = {
  home: '/art',
  homeWithLanguage: '/:lang/art',
  services: '/art/services',
  servicesWithLanguage: '/:lang/art/services',
  projects: '/art/projects',
  projectsWithLanguage: '/:lang/art/projects',
  projectDescription: '/art/projects/:id',
  projectDescriptionWithLanguage: '/:lang/art/projects/:id',
  portfolio: '/art/portfolio',
  portfolioWithLanguage: '/:lang/art/portfolio',
  subPortfolio: '/art/portfolio/:id',
  subPortfolioWithLanguage: '/:lang/art/portfolio/:id',
  morePortfolio: '/art/portfolio/pdfportfolio/:slug',
  morePortfolioWithLanguage: '/:lang/art/portfolio/pdfportfolio/:slug',
  contact: '/art/contact',
  contactWithLanguage: '/:lang/art/contact',
  pageNotFoundWithLang: '/:lang/art/*',
};
